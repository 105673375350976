import { UIIcon } from '@klappir/types';

export const HTMLIcon = (props: UIIcon.SVGProps) => {
  const width = props.width ?? '26';
  const height = props.height ?? '24';
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.82031 23.25C2.42249 23.25 2.04096 23.092 1.75965 22.8107C1.47835 22.5294 1.32031 22.1478 1.32031 21.75V2.25C1.32031 1.85218 1.47835 1.47064 1.75965 1.18934C2.04096 0.908035 2.42249 0.75 2.82031 0.75H13.4493C13.8469 0.750085 14.2281 0.907982 14.5093 1.189L20.3813 7.061C20.6623 7.3422 20.8202 7.72345 20.8203 8.121V12.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8203 8.25H14.8203C14.4225 8.25 14.041 8.09196 13.7597 7.81066C13.4783 7.52936 13.3203 7.14782 13.3203 6.75V0.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.57031 22.5V15"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57031 22.5V15"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.57031 19.5H8.57031"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0703 22.5078V15.0078"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5703 15H13.5703"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0703 22.5V15L17.8203 18.75L15.5703 15V22.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5703 15V21C22.5703 21.3978 22.7283 21.7794 23.0097 22.0607C23.291 22.342 23.6725 22.5 24.0703 22.5H24.5703"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
