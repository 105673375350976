import { UIIcon } from '@klappir/types';

export const External = (props: UIIcon.SVGProps) => {
  const width = props.width ?? '24';
  const height = props.height ?? '24';
  const stroke = props.color ?? '#000000';
  return (
    <svg viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 7.5h-5a1 1 0 0 0 -1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1 -1v-4.97"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      ></path>
      <path
        d="m7.5 16.514 12 -12"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      ></path>
      <path
        d="m13.5 4.514 6 0 0 6"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      ></path>
    </svg>
  );
};
