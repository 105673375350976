import { gql, useQuery } from '@klappir/data/graphql';
import { UINav } from '@klappir/types';

import { web } from '../client';
import { resolveLocales } from '../util';

export const GET_GLOBALS = gql`
  query getGlobals($locales: [Locale!]!) {
    globals(locales: $locales) {
      company {
        name
        logoId
        phone
        address1
        address2
        postCode
        city
        country
        kennitala
      }
      cookieBannerText
      cookieBannerAcceptButtonText
      cookieBannerDeclineButtonText
      socialMediaTitle
      socialMediaLinks
      headerLoginButtonText
      headerLoginButtonLink {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerRegisterButtonText
      headerRegisterButtonLink {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem1Title
      headerItem1Link {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem1SubLinks {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem2Title
      headerItem2Link {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem2SubLinks {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem3Title
      headerItem3Link {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem3SubLinks {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem4Title
      headerItem4Link {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem4SubLinks {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem5Title
      headerItem5Link {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem5SubLinks {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem6Title
      headerItem6Link {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      headerItem6SubLinks {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }

      footerCol1Title
      footerCol1Links {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      footerCol2Title
      footerCol2Links {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      footerCol3Title
      footerCol3Links {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      footerCol4Title
      footerCol4Links {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      footerCol5Title
      footerCol5Links {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
      footerBottomRowLinks {
        ... on Page {
          slug
          title
          navigationLabel
        }
        ... on Article {
          slug
          title
          navigationLabel
        }
        ... on ExternalLink {
          url
          title
          navigationLabel
          asset {
            url
          }
        }
      }
    }
  }
`;

export const getGlobals = async (locale?: string) => {
  const { data } = await web.query({
    query: GET_GLOBALS,
    variables: { locales: resolveLocales(locale) },
  });
  return data?.globals?.[0] as UINav.Global.Globals;
};

export const useGlobals = (locale?: string) => {
  const { data } = useQuery(GET_GLOBALS, {
    variables: { locales: resolveLocales(locale) },
  });
  return data?.globals?.[0] as UINav.Global.Globals;
};
