import { darken, lighten, transparentize } from 'polished';
import React, { PropsWithChildren } from 'react';

import { UIBrand, UIButton, UIIcon } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Icon, iconAnimations, StyledIcon } from '@klappir/ui/icon';
import {
  boxSizingReset,
  easings,
  fonts,
  keyframes,
  media,
  rem,
} from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

export const StyledLabel = styled.span<UIButton.Props>`
  ${({ loading, variant, colorScheme, theme }) => css`
    & {
      position: relative;
      z-index: 2;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding: 0 ${variant?.match(/text|icon/g) ? '0.5em' : '1.5em'};

      color: ${theme.button[variant || 'primary'][colorScheme || 'default']
        .text};

      transition: 100ms opacity, 300ms color;
      transition-delay: 0ms;

      opacity: ${loading ? '0' : '1'};

      ${StyledIcon} {
        margin-left: 0.5em;
        height: 1.5em;

        .stroke {
          stroke: ${theme.button[variant || 'primary'][colorScheme || 'default']
            .text};
        }
      }
    }
  `}
`;

export const StyledButton = styled.button<UIButton.Props>`
  ${({ disabled, loading, variant, colorScheme, icon, theme }) => {
    const primaryColor =
      theme.button[variant || 'primary'][colorScheme || 'default'].primaryColor;
    const loadingColor =
      theme.button[variant || 'primary'][colorScheme || 'default'].loadingColor;
    const focusColor =
      theme.button[variant || 'primary'][colorScheme || 'default'].focusColor;
    const focusText =
      theme.button[variant || 'primary'][colorScheme || 'default'].focusText;

    return css`
      & {
        cursor: ${loading ? `wait` : `pointer`};
        position: relative;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin: 0;
        padding: ${rem(12)} 0;
        line-height: ${rem(24)};

        font-size: ${rem(16)};
        font-weight: ${variant === 'icon'
          ? fonts.weight.regular
          : fonts.weight.semibold};
        font-family: ${fonts.primaryFont};
        text-decoration: none;

        background: none;
        border: 0;
        outline: 0;

        -webkit-appearance: none;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &:focus {
          border: 2px solid ${getColor('blue')};
        }

        ${boxSizingReset}
      }

      &:before,
      &:after {
        content: '';
        display: block;
        margin: auto;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      ${variant === 'icon'
        ? css`
            &:before {
              display: none;
            }
          `
        : variant === 'text'
        ? css`
            ${StyledLabel} {
              display: inline-block;
            }
            &:before {
              position: relative;
              z-index: 1;

              display: inline-block;
              vertical-align: middle;
              width: ${rem(24)};
              height: ${rem(2)};

              transition-property: width, height, border-radius, border-color,
                background-color;
              transition-duration: 300ms;
              transition-timing-function: ${easings.easeOutExpo};

              background: ${loading ? 'transparent' : primaryColor};
              border: none;
            }
          `
        : css`
            &:before {
              width: ${loading ? rem(48) : '100%'};
              ${loading ? `height: ${rem(48)};` : ''}
              z-index: 1;

              transition-property: width, height, border-radius, border-color,
                background-color;
              transition-duration: ${loading
                ? '300ms'
                : '100ms, 100ms, 100ms, 300ms, 300ms'};
              transition-timing-function: ${easings.easeOutExpo};

              ${variant === 'outline'
                ? 'border: 2px solid'
                : 'background-color:'} ${primaryColor};
            }
          `}

      &:after {
        width: ${rem(24)};
        height: ${rem(24)};
        z-index: 3;

        border-top: 2px solid ${transparentize(0.8, loadingColor)};
        border-right: 2px solid ${transparentize(0.8, loadingColor)};
        border-bottom: 2px solid ${transparentize(0.8, loadingColor)};
        border-left: 2px solid ${loadingColor};

        transform: translateZ(0);

        transition: 100ms opacity;
        transition-duration: ${loading ? '300ms' : '100ms'};
        transition-delay: ${loading ? '100ms' : '0ms'};

        opacity: ${loading ? '1' : '0'};
      }

      ${loading
        ? css`
            &:after {
              animation: ${keyframes.rotate} 1.1s infinite linear;
            }
          `
        : css`
            &:focus,
            &:hover {
              ${variant === 'text'
                ? css`
                    &:before {
                      width: ${rem(48)};
                      transition-duration: 500ms;
                    }
                  `
                : css``}
              ${variant?.match(/outline|text|icon/g)
                ? css`
                    ${StyledLabel} {
                      color: ${darken(0.1, primaryColor)};
                    }
                    ${StyledIcon} .stroke {
                      stroke: ${darken(0.1, primaryColor)};
                    }
                    ${icon
                      ? css`
                          ${StyledIcon} {
                            ${iconAnimations(icon)}
                          }
                        `
                      : ''}
                  `
                : ''}
              &:before {
                ${variant === 'outline'
                  ? 'border-color:'
                  : 'background-color:'} ${darken(0.1, primaryColor)};
              }
            }
          `}

      ${disabled
        ? css`
            & {
              pointer-events: none;
              user-select: none;
              cursor: not-allowed;
              opacity: 0.5;
            }
          `
        : css``}

      &:focus:before {
        background-color: ${focusColor};
        border: none;
      }
      &:focus ${StyledLabel} {
        color: ${focusText};
      }
    `;
  }}
`;

export const Button: React.FC<PropsWithChildren<UIButton.Props>> = ({
  children,
  ...props
}) => {
  return (
    <StyledButton
      as={props?.href ? 'a' : 'button'}
      disabled={props.loading === true}
      {...props}
    >
      <StyledLabel {...props}>
        {children}
        {props?.variant === 'icon' && props?.icon && <Icon id={props.icon} />}
      </StyledLabel>
    </StyledButton>
  );
};

export type KlappirButtonProps = UIButton.Props & {
  label: string | undefined;
  target?: string;
  icon?: UIIcon.IconName;
  href?: string;
  centered?: boolean;
  color?: UIBrand.ColorName;
};

export const KlappirStyledButton = styled.button<KlappirButtonProps>`
  ${(props) => {
    const { variant, centered = true, color } = props;
    const primaryColor = color ? getColor(color) : getColor('green');
    const textColor =
      variant === 'outline' || variant === 'ghost'
        ? primaryColor
        : getColor('white');

    return css`
      border: ${variant === 'ghost' ? 'none' : `1px solid ${primaryColor}`};
      padding: ${rem(8)};
      color: ${textColor};
      border-radius: 25px;
      background-color: ${variant === 'ghost'
        ? 'transparent'
        : variant === 'outline' || variant === 'outline-transparent'
        ? 'transparent'
        : primaryColor};
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      margin: ${centered ? '0 auto' : '0'};

      &:hover {
        cursor: pointer;
        background-color: ${variant === 'ghost'
          ? 'transparent'
          : variant === 'outline' || variant === 'outline-transparent'
          ? primaryColor
          : darken(0.1, primaryColor)};
        color: ${variant === 'ghost'
          ? primaryColor
          : variant === 'outline' || variant === 'outline-transparent'
          ? getColor('white')
          : getColor('white')};
        .buttonIcon svg {
          stroke: ${variant === 'ghost' ? primaryColor : '#fff'};
        }
      }

      ${(props: KlappirButtonProps) => {
        if (props.variant === 'outline-transparent') {
          return css`
            background: transparent;
            border: 1px solid ${primaryColor};
            color: ${primaryColor};

            &:hover {
              background: ${primaryColor};
              color: white;
            }

            &:disabled {
              background: transparent;
              border-color: ${getColor('gray', 100, 50)};
              color: ${getColor('gray', 100, 50)};
            }
          `;
        }
        return '';
      }}
    `;
  }}
`;

const IconWrapper = styled.div`
  padding: 0;
  width: 25px;
  height: 25px;
  margin-top: 1px;
`;

const ButtonLabel = styled.div<{ icon: string }>`
  margin: 0;
  padding: 0;
  margin-right: ${(props) => (props.icon !== '' ? '10px' : '0')};
  padding-top: 0px;
  text-align: center;
  font-weight: 600;
  ${media.md.below`padding-top:5px;`};
`;

export const KlappirButton = (props: KlappirButtonProps) => {
  const { label, target, icon, href = '#', variant } = props;
  return (
    <KlappirStyledButton
      onClick={() =>
        target !== '_blank' ? (window.location.href = href) : window.open(href)
      }
      {...props}
    >
      <ButtonLabel icon={icon ?? ''}>{label}</ButtonLabel>
      {icon !== undefined ? (
        <IconWrapper className="buttonIcon">
          <Icon
            color={
              variant == 'outline' || variant == 'ghost' ? 'green' : 'white'
            }
            library="klappir"
            id={icon}
          />
        </IconWrapper>
      ) : null}
    </KlappirStyledButton>
  );
};
