import React, { PropsWithChildren } from 'react';
import ReactCookieConsent from 'react-cookie-consent';

import { GoogleAnalyticsScripts } from '@klappir/analytics/google';
import { UINav } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { StyledButton, StyledLabel } from '@klappir/ui/button';
import { RenderMarkdown } from '@klappir/ui/text';
import { deleteAllCookies } from '@klappir/util/cookie';
import { easings, rem } from '@klappir/util/styles';
import { createGlobalStyle } from '@klappir/vendor/styled';

const CookieConsentStyles = createGlobalStyle`
  .CookieConsent {

    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;

    display: flex;
    margin: 0 ${rem(16)} ${rem(16)} 0;
    padding: ${rem(10)} ${rem(16)} ${rem(10)} ${rem(20)};
    width: 60vw;
    max-width: ${rem(500)};
    line-height: 1.5;

    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: center;

    text-align: center;
    font-size: ${rem(14)};
    color: black;

    box-shadow: 0 -10px 15px -20px ${getColor('black', undefined, 50)},
      10px 0 15px -20px ${getColor('black', undefined, 50)},
      -10px 0 15px -20px ${getColor('black', undefined, 50)},
      0 0 20px 0 #3d3d3d10;

    transform-origin: bottom right;
    transform: translate3d(0, 0, 0);

    transition: transform 0.2s ${easings.easeInCubic}, opacity 0.2s;
    will-change: transform, opacity;

    &:hover {
      transition: transform 1s ${easings.easeOutCubic}, opacity 0.2s;
      transform: translate3d(${rem(-8)}, ${rem(-8)}, 0);
    }

    background: ${getColor('white', 100, 99)};

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      backdrop-filter: blur(50px);
      background: ${getColor('white', 100, 75)};
    }


    button{
      margin: ${rem(16)} ${rem(10)} ${rem(0)};
      height: ${rem(42)};
    }

    @media only screen and (max-width: 860px) {
      width: 100%;
      margin: 0;
      div{
        display: contents;
      }
      .CookieButton{
        margin-right: auto;
      }
      .CookieButtonDecline{
        margin-left: auto;
      }
    }

  }
`;

const ButtonComponent: React.FC<PropsWithChildren> = ({
  children,
  ...props
}) => {
  return (
    <StyledButton colorScheme="destructive" variant="outline" {...props}>
      <StyledLabel colorScheme="destructive" variant="outline" {...props}>
        {children}
      </StyledLabel>
    </StyledButton>
  );
};

export const CookieBanner: React.FC<UINav.Global.CookieBanner> = ({
  cookieBannerText,
  cookieBannerAcceptButtonText,
  cookieBannerDeclineButtonText,
}) => {
  const cookieName = 'CookieConsent';
  const [runScripts, setRunScripts] = React.useState(true);

  return (
    <>
      <CookieConsentStyles />

      <ReactCookieConsent
        containerClasses="CookieConsent"
        ButtonComponent={ButtonComponent}
        buttonClasses="CookieButton"
        declineButtonClasses="CookieButtonDecline"
        customButtonProps={{
          variant: 'primary',
          colorScheme: 'default',
        }}
        disableStyles
        cookieName={cookieName}
        buttonText={cookieBannerAcceptButtonText}
        declineButtonText={cookieBannerDeclineButtonText}
        enableDeclineButton
        onAccept={() => {
          setRunScripts(true);
        }}
        onDecline={() => {
          setRunScripts(false);
          deleteAllCookies(cookieName);
        }}
      >
        <RenderMarkdown>{cookieBannerText}</RenderMarkdown>
      </ReactCookieConsent>
      {runScripts && <GoogleAnalyticsScripts />}
    </>
  );
};
