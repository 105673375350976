import { gql, useQuery } from '@klappir/data/graphql';
import { DataGraphcms } from '@klappir/types';

import { web } from '../client';
import { resolveLocales } from '../util';

export const GET_PARTNERS = gql`
  query getPartners($locales: [Locale!]!, $stage: Stage! = PUBLISHED) {
    partners(orderBy: published_DESC, locales: $locales, stage: $stage) {
      id
      slug
      title
      published
      featured
      summary
      navigationLabel
      thumbnail: image {
        url(
          transformation: {
            image: { resize: { width: 350, height: 220, fit: crop } }
          }
        )
      }
      image {
        url(
          transformation: {
            image: { resize: { width: 830, height: 520, fit: crop } }
          }
        )
      }
      authors {
        name
        image {
          url
        }
      }
      countries
      partnerType
      specialization
      seo {
        title
        description
        keywords
        noIndex
        image {
          url(
            transformation: {
              image: { resize: { width: 1200, height: 627, fit: crop } }
            }
          )
        }
      }
    }
  }
`;

export const GET_FILTER_CONFIGS = gql`
  query getFilterConfigs {
    __type(name: "Country") {
      enumValues {
        name
        description
      }
    }
    partnerTypes: __type(name: "PartnerType") {
      enumValues {
        name
        description
      }
    }
    specializations: __type(name: "Specialization") {
      enumValues {
        name
        description
      }
    }
  }
`;

interface EnumValue {
  name: string;
  description: string;
}

interface FilterOption {
  value: string;
  label: string;
}

export const getPartners = async (locale?: string) => {
  const { data } = await web.query({
    query: GET_PARTNERS,
    variables: { locales: resolveLocales(locale) },
  });

  return data?.partners as DataGraphcms.QueryResultPartner[];
};

export const getFilterConfigs = async () => {
  const { data } = await web.query({
    query: GET_FILTER_CONFIGS,
  });

  const formatOptions = (values: EnumValue[]): FilterOption[] => {
    return [
      { value: '', label: 'All' },
      ...(values || []).map((v) => ({
        value: v.name.toLowerCase(),
        label: v.description || v.name,
      })),
    ];
  };

  return {
    countries: formatOptions(data?.__type?.enumValues || []),
    partnerTypes: formatOptions(data?.partnerTypes?.enumValues || []),
    specializations: formatOptions(data?.specializations?.enumValues || []),
  };
};

export const useFilterConfigs = () => {
  const { data } = useQuery(GET_FILTER_CONFIGS);

  const formatOptions = (values: EnumValue[]): FilterOption[] => {
    return [
      { value: '', label: 'All' },
      ...(values || []).map((v) => ({
        value: v.name.toLowerCase(),
        label: v.description || v.name,
      })),
    ];
  };

  return {
    countries: formatOptions(data?.__type?.enumValues || []),
    partnerTypes: formatOptions(data?.partnerTypes?.enumValues || []),
    specializations: formatOptions(data?.specializations?.enumValues || []),
  };
};

export const GET_PARTNER = gql`
  query getPartner($slug: String, $locales: [Locale!]!) {
    partners(where: { slug: $slug }, locales: $locales) {
      localizations(includeCurrent: true) {
        locale
        slug
        title
      }
      slug
      title
      published
      summary
      thumbnail: image {
        url(
          transformation: {
            image: { resize: { width: 350, height: 220, fit: crop } }
          }
        )
      }
      image {
        url(
          transformation: {
            image: { resize: { width: 830, height: 520, fit: crop } }
          }
        )
      }
      authors {
        name
        image {
          url
        }
      }
      seo {
        title
        description
        keywords
        noIndex
        image {
          url(
            transformation: {
              image: { resize: { width: 1200, height: 627, fit: crop } }
            }
          )
        }
      }
      blocks {
        __typename
        ... on BlockHero {
          layout
          title
          description
          primaryButtonText
          primaryButtonLink {
            __typename
          }
          secondaryButtonText
          secondaryButtonLink {
            __typename
          }
          illustration
          image {
            url(
              transformation: {
                image: { resize: { width: 1445, height: 487, fit: crop } }
              }
            )
          }
        }
        ... on BlockHubSpotForm {
          formId
          portalId
          region
        }
        ... on BlockText {
          title
          text
          ctaLinkLabel
          ctaLink {
            ... on Article {
              id
              slug
            }
            ... on Page {
              id
              slug
            }
            ... on ExternalLink {
              id
              url
            }
          }
        }
        ... on BlockQuote {
          quote
          person {
            name
            company {
              name
            }
          }
        }
        ... on BlockLogoCloud {
          slug
          companies {
            name
            logoId
            website
          }
        }
        ... on BlockPerson {
          people {
            name
            role
            image {
              url(
                transformation: {
                  image: { resize: { width: 400, height: 400, fit: crop } }
                }
              )
            }
          }
        }
      }
    }
  }
`;

export const getPartner = async (slug: string, locale?: string) => {
  const { data } = await web.query({
    query: GET_PARTNER,
    variables: { slug, locales: resolveLocales(locale) },
  });

  return data?.partners?.[0] as DataGraphcms.QueryResultPartner;
};
